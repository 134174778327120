// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// ** Axios Imports
import axios from 'axios'
import { api } from '../../../../utility/constants'

/******************* New Purchase Return List *******************/

export const getMISupplierList = createAsyncThunk('PurchaseReturnData/getMISupplierList', async (params) => {
  try {
    const response = await axios.post(`${api.api_url}/purchaseManagement/purchaseReturn/getPOSupplierDataJwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
      params = {jwtToken:response.data.body.token}
      const response1 = await axios.post(`${api.api_url}/purchaseManagement/purchaseReturn/getPOSupplierData`, params)
      if (response1 && response1.data && response1.data.body) {
        return {
          MISupplierList: response1.data.body && response1.data.body.SupplierList ? response1.data.body.SupplierList : [],
          ListUnit: response1.data.body && response1.data.body.UnitList ? response1.data.body.UnitList : []
        }
      } else {
        return {
          MISupplierList: response1.data.body ? response1.data.body.SupplierList : [],
          ListUnit: response1.data.body ? response1.data.body.UnitList : []
        }
      }
    } else {
      return {
        MISupplierList: response.data.body ? response.data.body.SupplierList : [],
        ListUnit: response.data.body ? response.data.body.UnitList : []
      }
    }
  } catch (error) {
    return {
      MISupplierList: [],
      ListUnit: []
    }
  }
})

export const GetMIDesignList = createAsyncThunk('PurchaseReturnData/getPODesignDataJwt', async (params) => {
  try {
    const response = await axios.post(`${api.api_url}/purchaseManagement/purchaseReturn/getPODesignDataJwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
      params = {jwtToken:response.data.body.token}
      const response1 = await axios.post(`${api.api_url}/purchaseManagement/purchaseReturn/getPODesignData`, params)
      if (response1 && response1.data && response1.data.body) {
        return {
          MIDesignList: response1.data.body && response1.data.body.PODesignList ? response1.data.body.PODesignList : []
        }
      } else {
        return {
          MIDesignList: response1.data.body ? response1.data.body.PODesignList : []
        }
      }
    } else {
      return {
        MIDesignList: response.data.body ? response.data.body.PODesignList : []
      }
    }
  } catch (error) {
    return {
      MIDesignList: []
    }
  }
})

export const GetMIMaterialList = createAsyncThunk('PurchaseReturnData/getPOMaterialData', async (params) => {
  try {
    const response = await axios.post(`${api.api_url}/purchaseManagement/purchaseReturn/getPOMaterialDataJwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
      params = {jwtToken:response.data.body.token}
      const response1 = await axios.post(`${api.api_url}/purchaseManagement/purchaseReturn/getPOMaterialData`, params)
      if (response1 && response1.data && response1.data.body) {
        return {
          MIMaterialList: response1.data.body && response1.data.body.POMaterialList ? response1.data.body.POMaterialList : []
        }
      } else {
        return {
          MIMaterialList: response1.data.body ? response1.data.body.POMaterialList : []
        }
      }
    } else {
      return {
        MIMaterialList: response.data.body ? response.data.body.POMaterialList : []
      }
    }
  } catch (error) {
    return {
      MIMaterialList: []
    }
  }
})

export const GetMITypeList = createAsyncThunk('PurchaseReturnData/getPOTypeData', async (params) => {
  try {
    const response = await axios.post(`${api.api_url}/purchaseManagement/purchaseReturn/getPOTypeDataJwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
      params = {jwtToken:response.data.body.token}
      const response1 = await axios.post(`${api.api_url}/purchaseManagement/purchaseReturn/getPOTypeData`, params)
      if (response1 && response1.data && response1.data.body) {
        return {
          MITypeList: response1.data.body && response1.data.body.POTypeList ? response1.data.body.POTypeList : []
        }
      } else {
        return {
          MITypeList: response1.data.body ? response1.data.body.POTypeList : []
        }
      }
    } else {
      return {
        MITypeList: response.data.body ? response.data.body.POTypeList : []
      }
    }
  } catch (error) {
    return {
      MITypeList: []
    }
  }
})

export const GetMIItemList = createAsyncThunk('PurchaseReturnData/GetMIItemList', async (params) => {
  try {
    const response = await axios.post(`${api.api_url}/purchaseManagement/purchaseReturn/getMIItemListJwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
      params = {jwtToken:response.data.body.token}
      const response1 = await axios.post(`${api.api_url}/purchaseManagement/purchaseReturn/getMIItemList`, params)
      if (response1 && response1.data && response1.data.body) {
        return {
          MIItemList: response1.data.body && response1.data.body.MI_ItemList ? response1.data.body.MI_ItemList : []
        }
      } else {
        return {
          MIItemList: response1.data.body ? response1.data.body.MI_ItemList : []
        }
      }
    } else {
      return {
        MIItemList: response.data.body ? response.data.body.MI_ItemList : []
      }
    }
  } catch (error) {
    return {
      MIItemList: []
    }
  }
})


// Save ItemWise Dispatch
export const savePurchaseReturn = createAsyncThunk('PurchaseReturnData/savePurchaseReturn', async (params) => {
  try {
    const response = await axios.post(`${api.api_url}/purchaseManagement/purchaseReturn/savePurchaseReturnJwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
      params = {jwtToken:response.data.body.token}
      const response1 = await axios.post(`${api.api_url}/purchaseManagement/purchaseReturn/savePurchaseReturn`, params)
      if (response1 && response1.data && response1.data.body) {
        return {
          statusFlag: response1.data.body && response1.data.body.statusFlag ? response1.data.body.statusFlag : 2,
          message: response1.data.body.message
        }
      } else {
        return {
          statusFlag: response1.data.body ? response1.data.body.statusFlag : 2,
          message: response1.data.body ? response1.data.body.message : 'Server not reachable.Please try again later'
        }
      }
    } else {
      return {
        statusFlag: response.data.body ? response.data.body.statusFlag : 2,
        message: response.data.body ? response.data.body.message : 'Server not reachable.Please try again later'
      }
    }
  } catch (error) {
    return {
      statusFlag: 2,
      message: 'Server not reachable.Please try again later'
    }
  }
})

//Edit Purchase REturn List
export const EditData = createAsyncThunk('PurchaseReturnData/EditData', async params => {
  try {
    const response = await axios.post(`${api.api_url}/purchaseManagement/purchaseReturn/editPurchaseReturnJwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
      params = {jwtToken:response.data.body.token}
    const response1 = await axios.post(`${api.api_url}/purchaseManagement/purchaseReturn/editPurchaseReturn`, params)
    if (response1 && response1.data && response1.data.body) {
     return {
      EditPurchaseReturnList:response1.data.body && response1.data.body.EditPurchaseReturnList ? response1.data.body.EditPurchaseReturnList  : []
     }
    } else {
     return {
      EditPurchaseReturnList: response1.data.body ? response1.data.body.EditPurchaseReturnList : []
     }
    }
   } else {
     return {
      EditPurchaseReturnList:response1.data.body ? response1.data.body.EditPurchaseReturnList : []
     }
    }
  } catch (error) {
    return {
      EditPurchaseReturnList:[]
    }    
  }  
})

/******************* Purcahse Return list *******************/
export const getPRFilterList = createAsyncThunk('PurchaseReturnData/getMaterialFilterListJwt', async (params) => {
  try {
    const response = await axios.post(`${api.api_url}/purchaseManagement/purchaseReturn/getPRFilterListJwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
      params = {jwtToken:response.data.body.token}
      const response1 = await axios.post(`${api.api_url}/purchaseManagement/purchaseReturn/getPRFilterList`, params)
      if (response1 && response1.data && response1.data.body) {
        return {
          Supplier_List: response1.data.body && response1.data.body.SupplierList ? response1.data.body.SupplierList : [],
          Design_List: response1.data.body && response1.data.body.DesignList ? response1.data.body.DesignList : [],
          Material_List: response1.data.body && response1.data.body.MaterialList ? response1.data.body.MaterialList : [],
          Type_List: response1.data.body && response1.data.body.TypeList ? response1.data.body.TypeList : []
        }
      } else {
        return {
          Supplier_List: response1.data.body ? response1.data.body.SupplierList : [],
          Design_List: response1.data.body ? response1.data.body.DesignList : [],
          Material_List: response1.data.body ? response1.data.body.MaterialList : [],
          Type_List: response1.data.body ? response1.data.body.TypeList : []
        }
      }
    } else {
      return {
        Supplier_List: response.data.body ? response.data.body.SupplierList : [],
        Design_List: response.data.body ? response.data.body.DesignList : [],
        Material_List: response.data.body ? response.data.body.MaterialList : [],
        Type_List: response.data.body ? response.data.body.TypeList : []
      }
    }
  } catch (error) {
    return {
      Material_List: [],
      Supplier_List: [],
      Design_List: [],
      Type_List: []
    }
  }
})

export const getPurchaseReturnList = createAsyncThunk('PurchaseReturnData/getPurchaseReturnList', async (params) => {
  try {
    const response = await axios.post(`${api.api_url}/purchaseManagement/purchaseReturn/getPurchaseReturnListJwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
      params = {jwtToken:response.data.body.token}
      const response1 = await axios.post(`${api.api_url}/purchaseManagement/purchaseReturn/getPurchaseReturnList`, params)
      if (response1 && response1.data && response1.data.body) {
        return {
          PurchseReturnList: response1.data.body && response1.data.body.PurchseReturnList ? response1.data.body.PurchseReturnList : [],
          TotalPurchseReturn: response1.data.body && response1.data.body.TotalPurchseReturn ? response1.data.body.TotalPurchseReturn : 0         
        }
      } else {
        return {
          PurchseReturnList: response1.data.body ? response1.data.body.PurchseReturnList : [],
          TotalPurchseReturn: response1.data.body ? response1.data.body.TotalPurchseReturn : 0
        }
      }
    } else {
      return {
        PurchseReturnList: [],
        TotalPurchseReturn: 0
      }
    }
  } catch (error) {
    return {
      PurchseReturnList: [],
      TotalPurchseReturn: 0
    }
  }
})

export const cancelPurchaseReturn = createAsyncThunk('PurchaseReturnData/cancelPurchaseReturn', async (params) => {
  try {
    const response = await axios.post(`${api.api_url}/purchaseManagement/purchaseReturn/cancelPurchaseReturnJwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
      params = {jwtToken:response.data.body.token}
      const response1 = await axios.post(`${api.api_url}/purchaseManagement/purchaseReturn/cancelPurchaseReturn`, params)
      if (response1 && response1.data && response1.data.body) {
        return {
          PR_Delete_Message: response1.data.body.message,
          PR_Delete_StatusFlag: response1.data.body && response1.data.body.statusFlag ? response1.data.body.statusFlag : 2
        }
      }  else {
        return {
          PR_Delete_Message: response1.data.body ? response1.data.body.Message : 'Server not reachable.Please try again later',
          PR_Delete_StatusFlag: response1.data.body ? response1.data.body.PR_Delete_statusFlag : 2
        }
      }
    }  else {
      return {
        PR_Delete_Message: 'Server not reachable.Please try again later',
        PR_Delete_StatusFlag: 2
      }
    }
  } catch (error) {
    return {
      PR_Delete_Message: 'Server not reachable.Please try again later',
      PR_Delete_StatusFlag: 2
    }
  }
  })

  export const deletePurchaseReturn = createAsyncThunk('PurchaseReturnData/deleteMaterialInward', async (params) => {
    try {
      const response = await axios.post(`${api.api_url}/purchaseManagement/purchaseReturn/deletePurchaseReturnJwt`, params)
      if (response && response.data && response.data.body && response.data.body.token) {
        params = {jwtToken:response.data.body.token}
        const response1 = await axios.post(`${api.api_url}/purchaseManagement/purchaseReturn/deletePurchaseReturn`, params)
        if (response1 && response1.data && response1.data.body) {
          return {
            PR_Delete_Message: response1.data.body.message,
            PR_Delete_StatusFlag: response1.data.body && response1.data.body.statusFlag ? response1.data.body.statusFlag : 2
          }
        }  else {
          return {
            PR_Delete_Message: response1.data.body ? response1.data.body.Message : 'Server not reachable.Please try again later',
            PR_Delete_StatusFlag: response1.data.body ? response1.data.body.PR_Delete_statusFlag : 2
          }
        }
      }  else {
        return {
          PR_Delete_Message: 'Server not reachable.Please try again later',
          PR_Delete_StatusFlag: 2
        }
      }
    } catch (error) {
      return {
        PR_Delete_Message: 'Server not reachable.Please try again later',
        PR_Delete_StatusFlag: 2
      }
    }
    })

export const GetDispatchedItemList = createAsyncThunk('PurchaseReturnData/getDispatchListBasedOnItemCustomerJwt', async (params) => {
  try {
    const response = await axios.post(`${api.api_url}/purchaseManagement/purchaseReturn/getDispatchListBasedOnItemCustomerJwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
      params['jwtToken'] = response.data.body.token
      const response1 = await axios.post(`${api.api_url}/purchaseManagement/purchaseReturn/getDispatchListBasedOnItemCustomer`, params)
      if (response1 && response1.data && response1.data.body) {
        return {
          DispatchedItemList: response1.data.body && response1.data.body.dispatchedArray ? response1.data.body.dispatchedArray : [],
          DispatchedItem_Message: response1.data.body && response1.data.body.dispatchedArray ? response1.data.body.Message : 'Server not reachable.Please try again later'
        }
      } else {
        return {
          DispatchedItemList: response1.data.body ? response1.data.body.dispatchedArray : [],
          DispatchedItem_Message: response1.data.body ? response1.data.body.Message : 'Server not reachable.Please try again later'
        }
      }
    } else {
      return {
        DispatchedItemList: response.data.body ? response.data.body.dispatchedArray : [],
        DispatchedItem_Message: response.data.body ? response.data.body.Message : 'Server not reachable.Please try again later'
      }
    }
  } catch (error) {
    return {
      DispatchedItem_Message: 'Server not reachable.Please try again later',
      DispatchedItemList: []
    }
  }
})

///
export const PrintPRList = createAsyncThunk('PurchaseReturnData/printPRlistJwt', async (params) => {
  try {
    const response = await axios.post(`${api.api_url}/purchaseManagement/purchaseReturn/getPRListJwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
      params['jwtToken'] = response.data.body.token
      const response1 = await axios.post(`${api.api_url}/purchaseManagement/purchaseReturn/getPRList`, params)
      if (response1 && response1.data && response1.data.body) {
        return {
          Print_PurchaseReturnList: response1.data.body && response1.data.body.goods_return_array ? response1.data.body.goods_return_array : [],
          Print_PRListItemMessage: response1.data.body && response1.data.body.goods_return_array ? response1.data.body.Message : 'Server not reachable.Please try again later',
          Print_PurchaseReturnCompany: response1.data.body && response1.data.body.Company_Array ? response1.data.body.Company_Array : [],
          Print_PurchaseReturnResult: 'true',
          Print_PurchaseReturnWidget: response1.data.body && response1.data.body.goodsReturnWidget ? response1.data.body.goodsReturnWidget : []
        }
      } else {
        return {
          Print_PurchaseReturnList: response1.data.body ? response1.data.body.goods_return_array : [],
          Print_PRListItemMessage: response1.data.body ? response1.data.body.Message : 'Server not reachable.Please try again later',
          Print_PurchaseReturnCompany: response1.data.body ? response1.data.body.Company_Array : [],
          Print_PurchaseReturnResult: 'false',
          Print_PurchaseReturnWidget: response1.data.body ? response1.data.body.goodsReturnWidget : []
        }
      }
    } else {
      return {
        Print_PurchaseReturnList: [],
        Print_PRListItemMessage: 'Server not reachable.Please try again later',
        Print_PurchaseReturnCompany: [],
        Print_PurchaseReturnResult: 'false',
        Print_PurchaseReturnWidget: []
      }
    }
  } catch (error) {
    return {
      Print_PurchaseReturnList: [],
      Print_PRListItemMessage: 'Server not reachable.Please try again later',
      Print_PurchaseReturnCompany: [],
      Print_PurchaseReturnResult: 'false',
      Print_PurchaseReturnWidget: []
    }
  }
})

export const PurchaseReturnData = createSlice({
  name: 'PurchaseReturnData',
  initialState: {
    params: {},
    statusFlag: 0,
    message: '',
    MISupplierList: [],
    ListUnit: [],
    Customer_Message: '',
    MIDesignList: [],
    MIMaterialList: [],
    MITypeList: [],
    MIItemList: [],
    Material_List: [],
    Supplier_List: [],
    Design_List: [],
    Type_List: [],
    ItemList: [],
    DispatchedItemList: [],
    DispatchedItem_Message: '',
    PurchseReturnList: [],
    PRListItemMessage: '',
    TotalPurchseReturn: 0,
    overallTotalsets: 0,
    overallTotalPieces: 0,
    PurchaseReturnWidget: [],
    Print_PurchaseReturnList: [],
    Print_PRListItemMessage: '',
    Print_PurchaseReturnCompany: [],
    Print_PurchaseReturnResult: 'false',
    Print_PurchaseReturnWidget: [],
    Filter_Message: '',
    Customer_List: [],
    Item_List: [],
    Customer_Lists: [],
    PR_Delete_Message: '',
    PR_Delete_StatusFlag: 0,
    EditPurchaseReturnList: []
  },
  reducers: {
    handleStatusFlag: (state, action) => {
      state.statusFlag = action.payload.statusFlag     
      state.message = action.payload.message      
      state.FG_Delete_message = action.payload.FG_Delete_message
      state.FG_Delete_statusFlag = action.payload.FG_Delete_statusFlag  
      state.PR_Delete_Message = action.payload.PR_Delete_Message
      state.PR_Delete_StatusFlag = action.payload.PR_Delete_StatusFlag  
    },
    handleedit: (state, action) => {
      state.editaction = action.payload
    },
    clearstoreData: (state, action) => {
      state.MISupplierList = action.payload.MISupplierList
      state.ListUnit = action.payload.ListUnit
      state.Customer_Message = action.payload.Customer_Message
      state.MIDesignList = action.payload.MIDesignList
      state.MIMaterialList = action.payload.MIMaterialList
      state.MITypeList = action.payload.MITypeList
      state.MIItemList = action.payload.MIItemList
      state.DispatchedItemList = action.payload.DispatchedItemList
      state.DispatchedItem_Message = action.payload.DispatchedItem_Message
      state.PurchseReturnList = action.payload.PurchseReturnList
      state.PRListItemMessage = action.payload.PRListItemMessage
      state.TotalPurchseReturn = action.payload.TotalPurchseReturn
      state.overallTotalsets = action.payload.overallTotalsets
      state.overallTotalPieces = action.payload.overallTotalPieces
      state.PurchaseReturnWidget = action.payload.PurchaseReturnWidget
      state.Print_PurchaseReturnList = action.payload.Print_PurchaseReturnList
      state.Print_PRListItemMessage = action.payload.Print_PRListItemMessage
      state.Print_PurchaseReturnCompany = action.payload.Print_PurchaseReturnCompany
      state.Print_PurchaseReturnResult = action.payload.Print_PurchaseReturnResult
      state.Print_PurchaseReturnWidget = action.payload.Print_PurchaseReturnWidget
      state.Customer_List = action.payload.Customer_List
      state.Item_List = action.payload.Item_List
      state.Filter_Message = action.payload.Filter_Message   
      state.Supplier_List = action.payload.Supplier_List
      state.Design_List = action.payload.Design_List
      state.Material_List = action.payload.Material_List  
      state.Type_List = action.payload.Type_List  
      state.EditPurchaseReturnList = action.payload.EditPurchaseReturnList
    }
  },
  extraReducers: builder => {
    builder.addCase(getPRFilterList.fulfilled, (state, action) => {
      state.Supplier_List = action.payload.Supplier_List
      state.Design_List = action.payload.Design_List
      state.Material_List = action.payload.Material_List
      state.Type_List = action.payload.Type_List
    }).addCase(getMISupplierList.fulfilled, (state, action) => {
      state.MISupplierList = action.payload.MISupplierList
      state.ListUnit = action.payload.ListUnit
    }).addCase(GetMIDesignList.fulfilled, (state, action) => {
      state.MIDesignList = action.payload.MIDesignList
    }).addCase(GetMIMaterialList.fulfilled, (state, action) => {
      state.MIMaterialList = action.payload.MIMaterialList
    }).addCase(GetMITypeList.fulfilled, (state, action) => {
      state.MITypeList = action.payload.MITypeList 
    }).addCase(GetMIItemList.fulfilled, (state, action) => {
      state.MIItemList = action.payload.MIItemList 
    }).addCase(GetDispatchedItemList.fulfilled, (state, action) => {
      state.DispatchedItemList = action.payload.DispatchedItemList
      state.DispatchedItem_Message = action.payload.DispatchedItem_Message
    }).addCase(EditData.fulfilled, (state, action) => {
      state.EditPurchaseReturnList = action.payload.EditPurchaseReturnList
    }).addCase(savePurchaseReturn.fulfilled, (state, action) => {
      state.statusFlag = action.payload.statusFlag
      state.message = action.payload.message
    }).addCase(getPurchaseReturnList.fulfilled, (state, action) => {
      state.PurchseReturnList = action.payload.PurchseReturnList
      state.TotalPurchseReturn = action.payload.TotalPurchseReturn
    }).addCase(PrintPRList.fulfilled, (state, action) => {
      state.Print_PurchaseReturnList = action.payload.Print_PurchaseReturnList
      state.Print_PRListItemMessage = action.payload.Print_PRListItemMessage
      state.Print_PurchaseReturnCompany = action.payload.Print_PurchaseReturnCompany
      state.Print_PurchaseReturnResult = action.payload.Print_PurchaseReturnResult
      state.Print_PurchaseReturnWidget = action.payload.Print_PurchaseReturnWidget
    }).addCase(cancelPurchaseReturn.fulfilled, (state, action) => {
      state.PR_Delete_Message = action.payload.PR_Delete_Message
      state.PR_Delete_StatusFlag = action.payload.PR_Delete_StatusFlag
    })
    .addCase(deletePurchaseReturn.fulfilled, (state, action) => {
      state.PR_Delete_Message = action.payload.PR_Delete_Message
      state.PR_Delete_StatusFlag = action.payload.PR_Delete_StatusFlag
    })
  }
})
export const {
  handleStatusFlag, handleedit, clearstoreData
} = PurchaseReturnData.actions
export default PurchaseReturnData.reducer

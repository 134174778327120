// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// ** Axios Imports
import axios from 'axios'
import { api } from '../../../../utility/constants'

/// New Material inward
export const getPOSupplierList = createAsyncThunk('MaterialInwardData/getPOSupplierListJwt', async (params) => {
  try {
    const response = await axios.post(`${api.api_url}/purchaseManagement/materialInward/getPOSupplierListJwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
      params = {jwtToken:response.data.body.token}
      const response1 = await axios.post(`${api.api_url}/purchaseManagement/materialInward/getPOSupplierList`, params)
      if (response1 && response1.data && response1.data.body) {
        return {
          POSupplierList: response1.data.body && response1.data.body.SupplierList ? response1.data.body.SupplierList : []
        }
      } else {
        return {
          POSupplierList: response1.data.body ? response1.data.body.SupplierList : []
        }
      }
    } else {
      return {
        POSupplierList: response.data.body ? response.data.body.SupplierList : []
      }
    }
  } catch (error) {
    return {
      POSupplierList: []
    }
  }
})

export const getPODesignList = createAsyncThunk('MaterialInwardData/getPODesignListJwt', async (params) => {
  try {
    const response = await axios.post(`${api.api_url}/purchaseManagement/materialInward/getPODesignListJwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
      params = {jwtToken:response.data.body.token}
      const response1 = await axios.post(`${api.api_url}/purchaseManagement/materialInward/getPODesignList`, params)
      if (response1 && response1.data && response1.data.body) {
        return {
          PODesignList: response1.data.body && response1.data.body.PODesignList ? response1.data.body.PODesignList : []
        }
      } else {
        return {
          PODesignList: response1.data.body ? response1.data.body.PODesignList : []
        }
      }
    } else {
      return {
        PODesignList: response.data.body ? response.data.body.PODesignList : []
      }
    }
  } catch (error) {
    return {
      PODesignList: []
    }
  }
})

export const getPOList = createAsyncThunk('MaterialInwardData/getPOListJwt', async (params) => {
  try {
    const response = await axios.post(`${api.api_url}/purchaseManagement/materialInward/getPOListJwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
      params = {jwtToken:response.data.body.token}
      const response1 = await axios.post(`${api.api_url}/purchaseManagement/materialInward/getPOList`, params)
      if (response1 && response1.data && response1.data.body) {
        return {
          POList: response1.data.body && response1.data.body.POList ? response1.data.body.POList : []
        }
      } else {
        return {
          POList: response1.data.body ? response1.data.body.POList : []
        }
      }
    } else {
      return {
        POList: response.data.body ? response.data.body.POList : []
      }
    }
  } catch (error) {
    return {
      POList: []
    }
  }
})

export const getPOItemList = createAsyncThunk('MaterialInwardData/getPOItemList', async (params) => {
  try {
    const response = await axios.post(`${api.api_url}/purchaseManagement/materialInward/getPOItemListJwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
      params = {jwtToken:response.data.body.token}
      const response1 = await axios.post(`${api.api_url}/purchaseManagement/materialInward/getPOItemList`, params)
      if (response1 && response1.data && response1.data.body) {
        return {
          POItemList: response1.data.body && response1.data.body.POItemList ? response1.data.body.POItemList : []
        }
      } else {
        return {
          POItemList: response1.data.body ? response1.data.body.POItemList : []
        }
      }
    } else {
      return {
        POItemList: response.data.body ? response.data.body.POItemList : []
      }
    }
  } catch (error) {
    return {
      POItemList: []
    }
  }
})

// Save MaterialInward
export const saveMaterialInward = createAsyncThunk('MaterialInwardData/saveMaterialInward', async (params) => {
  try {
    const response = await axios.post(`${api.api_url}/purchaseManagement/materialInward/saveMaterialInwardJwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
      params = {jwtToken:response.data.body.token}
      const response1 = await axios.post(`${api.api_url}/purchaseManagement/materialInward/saveMaterialInward`, params)
      if (response1 && response1.data && response1.data.body) {
        return {
          statusFlag: response1.data.body && response1.data.body.statusFlag ? response1.data.body.statusFlag : 2,
          message: response1.data.body.message
        }
      } else {
        return {
          statusFlag: response1.data.body ? response1.data.body.statusFlag : 2,
          message: response1.data.body ? response1.data.body.message : 'Server not reachable.Please try again later'
        }
      }
    } else {
      return {
        statusFlag: response.data.body ? response.data.body.statusFlag : 2,
        message: response.data.body ? response.data.body.message : 'Server not reachable.Please try again later'
      }
    }
  } catch (error) {
    return {
      statusFlag: 2,
      message: 'Server not reachable.Please try again later'
    }
  }
})

/******************* Material Inward list *******************/
export const getMIFilterList = createAsyncThunk('MaterialInwardData/getMaterialFilterListJwt', async (params) => {
  try {
    const response = await axios.post(`${api.api_url}/purchaseManagement/materialInward/getMaterialFilterListJwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
      params = {jwtToken:response.data.body.token}
      const response1 = await axios.post(`${api.api_url}/purchaseManagement/materialInward/getMaterialFilterList`, params)
      if (response1 && response1.data && response1.data.body) {
        return {
          Supplier_List: response1.data.body && response1.data.body.SupplierList ? response1.data.body.SupplierList : [],
          Design_List: response1.data.body && response1.data.body.DesignList ? response1.data.body.DesignList : [],
          Material_List: response1.data.body && response1.data.body.MaterialList ? response1.data.body.MaterialList : [],
          Type_List: response1.data.body && response1.data.body.TypeList ? response1.data.body.TypeList : []
        }
      } else {
        return {
          Supplier_List: response1.data.body ? response1.data.body.SupplierList : [],
          Design_List: response1.data.body ? response1.data.body.DesignList : [],
          Material_List: response1.data.body ? response1.data.body.MaterialList : [],
          Type_List: response1.data.body ? response1.data.body.TypeList : []
        }
      }
    } else {
      return {
        Supplier_List: response.data.body ? response.data.body.SupplierList : [],
        Design_List: response.data.body ? response.data.body.DesignList : [],
        Material_List: response.data.body ? response.data.body.MaterialList : [],
        Type_List: response.data.body ? response.data.body.TypeList : []
      }
    }
  } catch (error) {
    return {
      Material_List: [],
      Supplier_List: [],
      Design_List: [],
      Type_List: []
    }
  }
})
export const getMaterialInwardList = createAsyncThunk('MaterialInwardData/getMaterialInwardListJwt', async (params) => {
  try {
    const response = await axios.post(`${api.api_url}/purchaseManagement/materialInward/getMaterialInwardListJwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
      params = {jwtToken:response.data.body.token}
      const response1 = await axios.post(`${api.api_url}/purchaseManagement/materialInward/getMaterialInwardList`, params)
      if (response1 && response1.data && response1.data.body) {
        return {
          MaterialInwardList: response1.data.body && response1.data.body.MaterialInwardList ? response1.data.body.MaterialInwardList : [],
          TotalMaterialInward: response1.data.body && response1.data.body.TotalMaterialInward ? response1.data.body.TotalMaterialInward : 0
        }
      } else {
        return {
          MaterialInwardList: response1.data.body ? response1.data.body.MaterialInwardList : [],
          TotalMaterialInward: response1.data.body ? response1.data.body.TotalMaterialInward : 0
        }
      }
    } else {
      return {
        MaterialInwardList: [],
        TotalMaterialInward: 0
      }
    }
  } catch (error) {
    return {
      MaterialInwardList: [],
      TotalMaterialInward: 0
    }
  }
})


export const deleteMaterialInward = createAsyncThunk('MaterialInwardData/deleteMaterialInward', async (params) => {
  try {
    const response = await axios.post(`${api.api_url}/purchaseManagement/materialInward/deleteMaterialInwardJwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
      params = {jwtToken:response.data.body.token}
      const response1 = await axios.post(`${api.api_url}/purchaseManagement/materialInward/deleteMaterialInward`, params)
      if (response1 && response1.data && response1.data.body) {
        return {
          MI_Delete_Message: response1.data.body ? response1.data.body.message : 'Server not reachable.Please try again later',
          MI_Delete_StatusFlag: response1.data.body && response1.data.body.statusFlag ? response1.data.body.statusFlag : 2
        }
      }  else {
        return {
          MI_Delete_Message: response1.data.body ? response1.data.body.message : 'Server not reachable.Please try again later',
          MI_Delete_StatusFlag: response1.data.body ? response1.data.body.statusFlag : 2
        }
      }
    }  else {
      return {
        MI_Delete_Message: 'Server not reachable.Please try again later',
        MI_Delete_StatusFlag: 2
      }
    }
  } catch (error) {
    return {
      MI_Delete_Message: 'Server not reachable.Please try again later',
      MI_Delete_StatusFlag: 2
    }
  }
  })

  export const cancelMaterialInward = createAsyncThunk('MaterialInwardData/cancelMaterialInward', async (params) => {
    try {
      const response = await axios.post(`${api.api_url}/purchaseManagement/materialInward/cancelMaterialInwardJwt`, params)
      if (response && response.data && response.data.body && response.data.body.token) {
        params = {jwtToken:response.data.body.token}
        const response1 = await axios.post(`${api.api_url}/purchaseManagement/materialInward/cancelMaterialInward`, params)
        if (response1 && response1.data && response1.data.body) {
          return {
            MI_Delete_Message: response1.data.body ? response1.data.body.message : 'Server not reachable.Please try again later',
            MI_Delete_StatusFlag: response1.data.body && response1.data.body.statusFlag ? response1.data.body.statusFlag : 2
          }
        }  else {
          return {
            MI_Delete_Message: response1.data.body ? response1.data.body.message : 'Server not reachable.Please try again later',
            MI_Delete_StatusFlag: response1.data.body ? response1.data.body.statusFlag : 2
          }
        }
      }  else {
        return {
          MI_Delete_Message: 'Server not reachable.Please try again later',
          MI_Delete_StatusFlag: 2
        }
      }
    } catch (error) {
      return {
        MI_Delete_Message: 'Server not reachable.Please try again later',
        MI_Delete_StatusFlag: 2
      }
    }
    })
  
  //Edit Order List
export const EditData = createAsyncThunk('MaterialInwardData/EditData', async params => {
  try {
    const response = await axios.post(`${api.api_url}/purchaseManagement/materialInward/editMaterialInwardJwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
      params = {jwtToken:response.data.body.token}
    const response1 = await axios.post(`${api.api_url}/purchaseManagement/materialInward/editMaterialInward`, params)
    if (response1 && response1.data && response1.data.body) {
     return {
      EditMaterialInwardList:response1.data.body && response1.data.body.EditMaterialInwardList ? response1.data.body.EditMaterialInwardList  : []
     }
    } else {
     return {
      EditMaterialInwardList: response1.data.body ? response1.data.body.EditMaterialInwardList : []
     }
    }
   } else {
     return {
      EditMaterialInwardList:response1.data.body ? response1.data.body.EditMaterialInwardList : []
     }
    }
  } catch (error) {
    return {
      EditMaterialInwardList:[]
    }    
  }  
})

export const MaterialInwardData = createSlice({
  name: 'MaterialInwardData',
  initialState: {
    params: {},
    statusFlag: 0,
    message: '',
    POSupplierList: [],
    Customer_Message: '',
    POList: [],
    PODesignList: [],
    ItemList: [],
    Item_Message: '',
    POItemList: [],
    MaterialInwardList: [],
    TotalMaterialInward: 0,
    Material_List: [],
    Supplier_List: [],
    Design_List: [],
    Type_List: [],
    MI_Delete_Message: '',
    MI_Delete_StatusFlag: 0,
    EditMaterialInwardList: [] 
  },
  reducers: {
    handleStatusFlag: (state, action) => {
      state.statusFlag = action.payload.statusFlag     
      state.message = action.payload.message      
      state.MI_Delete_Message = action.payload.MI_Delete_Message
      state.MI_Delete_StatusFlag = action.payload.MI_Delete_StatusFlag  
    },
    handleedit: (state, action) => {
      state.editaction = action.payload
    },
    clearstoreData: (state, action) => {
      state.POSupplierList = action.payload.POSupplierList
      state.Customer_Message = action.payload.Customer_Message
      state.POList = action.payload.POList
      state.PODesignList = action.payload.PODesignList
      state.Item_Message = action.payload.Item_Message
      state.POItemList = action.payload.POItemList
      state.MaterialInwardList = action.payload.MaterialInwardList
      state.TotalMaterialInward = action.payload.TotalMaterialInward     
      state.Supplier_List = action.payload.Supplier_List
      state.Design_List = action.payload.Design_List
      state.Material_List = action.payload.Material_List  
      state.Type_List = action.payload.Type_List 
      state.EditMaterialInwardList = action.payload.EditMaterialInwardList 
    }
  }, 
  extraReducers: builder => {
    builder.addCase(getMIFilterList.fulfilled, (state, action) => {
      state.Supplier_List = action.payload.Supplier_List
      state.Design_List = action.payload.Design_List
      state.Material_List = action.payload.Material_List
      state.Type_List = action.payload.Type_List
    }).addCase(getPOSupplierList.fulfilled, (state, action) => {
      state.POSupplierList = action.payload.POSupplierList
    }).addCase(getPODesignList.fulfilled, (state, action) => {
      state.PODesignList = action.payload.PODesignList
    }).addCase(getPOList.fulfilled, (state, action) => {
      state.POList = action.payload.POList
    }).addCase(getPOItemList.fulfilled, (state, action) => {
      state.POItemList = action.payload.POItemList
    }).addCase(saveMaterialInward.fulfilled, (state, action) => {
      state.statusFlag = action.payload.statusFlag
      state.message = action.payload.message
    }).addCase(getMaterialInwardList.fulfilled, (state, action) => {
      state.MaterialInwardList = action.payload.MaterialInwardList
      state.TotalMaterialInward = action.payload.TotalMaterialInward
    }).addCase(deleteMaterialInward.fulfilled, (state, action) => {
      state.MI_Delete_Message = action.payload.MI_Delete_Message
      state.MI_Delete_StatusFlag = action.payload.MI_Delete_StatusFlag
    }).addCase(cancelMaterialInward.fulfilled, (state, action) => {
      state.MI_Delete_Message = action.payload.MI_Delete_Message
      state.MI_Delete_StatusFlag = action.payload.MI_Delete_StatusFlag
    }).addCase(EditData.fulfilled, (state, action) => {
      state.EditMaterialInwardList = action.payload.EditMaterialInwardList
    })
  }
})
export const {
  handleStatusFlag, handleedit, clearstoreData
} = MaterialInwardData.actions
export default MaterialInwardData.reducer

// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// ** Axios Imports
import axios from 'axios'
import { api } from '../../../../utility/constants'

// Filter
export const getPurchaseFilterData = createAsyncThunk('PurchaseOrder/getPurchaseFilterData', async params => {
  try {
    const response = await axios.post(`${api.api_url}/purchaseManagement/purchaseOrder/getPurchaseFilterDataJwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
    params['jwtToken'] = response.data.body.token
    const response1 = await axios.post(`${api.api_url}/purchaseManagement/purchaseOrder/getPurchaseFilterData`, params)
    if (response1 && response1.data && response1.data.body) {
     return {
      ListUnit:response1.data.body && response1.data.body.UnitList ? response1.data.body.UnitList  : []
     }
    } else {
     return {
      ListUnit:response1.data.body ? response1.data.body.UnitList : []
     }
    }
   } else {
     return {
      ListUnit:response1.data.body ? response1.data.body.UnitList : []
     }
    }
  } catch (error) {
    return {
      ListUnit:[]
    }    
  }  
})
export const onchangeSupllierData = createAsyncThunk('PurchaseOrder/onchangeSupllierData', async params => {
  try {
    const response = await axios.post(`${api.api_url}/purchaseManagement/purchaseOrder/ChangeAutoSupplierNameJwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
    params = {jwtToken:response.data.body.token}
    const response1 = await axios.post(`${api.api_url}/purchaseManagement/purchaseOrder/ChangeAutoSupplierName`, params)
    if (response1 && response1.data && response1.data.body) {
     return {
      ListSupllier:response1.data.body && response1.data.body.SupplierList ? response1.data.body.SupplierList  : []
     }
    } else {
     return {
      ListSupllier:response1.data.body ? response1.data.body.SupplierList : []
     }
    }
   } else {
     return {
      ListSupllier:response1.data.body ? response1.data.body.SupplierList : []
     }
    }
  } catch (error) {
    return {
      ListSupllier:[]
    }    
  }  
})

export const onchangeSupplierDetails = createAsyncThunk('PurchaseOrder/onchangeSupplierDetails', async params => {
  try {
    const response = await axios.post(`${api.api_url}/purchaseManagement/purchaseOrder/supplierListJwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
    params = {jwtToken:response.data.body.token}
    const response1 = await axios.post(`${api.api_url}/purchaseManagement/purchaseOrder/supplierList`, params)
    if (response1 && response1.data && response1.data.body) {
     return {
      SupllierList:response1.data.body && response1.data.body.SupllierList ? response1.data.body.SupllierList  : []
     }
    } else {
     return {
      SupllierList:response1.data.body ? response1.data.body.SupllierList : []
     }
    }
   } else {
     return {
      SupllierList:response1.data.body ? response1.data.body.SupllierList : []
     }
    }
  } catch (error) {
    return {
      SupllierList:[]
    }    
  }  
})
// Design Code search
export const onchangeDesignData = createAsyncThunk('PurchaseOrder/onchangeDesignData', async params => {
  try {
    const response = await axios.post(`${api.api_url}/purchaseManagement/purchaseOrder/ChangeAutoDesignNameJwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
    params = {jwtToken:response.data.body.token}
    const response1 = await axios.post(`${api.api_url}/purchaseManagement/purchaseOrder/ChangeAutoDesignName`, params)
    if (response1 && response1.data && response1.data.body) {
     return {
      ListDesign:response1.data.body && response1.data.body.DesignList ? response1.data.body.DesignList  : []
     }
    } else {
     return {
      ListDesign:response1.data.body ? response1.data.body.DesignList : []
     }
    }
   } else {
     return {
      ListDesign:response1.data.body ? response1.data.body.DesignList : []
     }
    }
  } catch (error) {
    return {
      ListDesign:[]
    }    
  }  
})

export const onchangeMaterialData = createAsyncThunk('PurchaseOrder/onchangeMaterialData', async params => {
  try {
    const response = await axios.post(`${api.api_url}/purchaseManagement/purchaseOrder/ChangeAutoMatrialNameJwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
    params = {jwtToken:response.data.body.token}
    const response1 = await axios.post(`${api.api_url}/purchaseManagement/purchaseOrder/ChangeAutoMatrialName`, params)
    if (response1 && response1.data && response1.data.body) {
     return {
      ListMaterial:response1.data.body && response1.data.body.MaterialList ? response1.data.body.MaterialList  : []
     }
    } else {
     return {
      ListMaterial:response1.data.body ? response1.data.body.MaterialList : []
     }
    }
   } else {
     return {
      ListMaterial:response1.data.body ? response1.data.body.MaterialList : []
     }
    }
  } catch (error) {
    return {
      ListMaterial:[]
    }    
  }  
})

export const getTypeBasedOnDesignData = createAsyncThunk('PurchaseOrder/getTypeBasedOnDesignData', async params => {
  try {
    const response = await axios.post(`${api.api_url}/purchaseManagement/purchaseOrder/getTypeBasedOnDesignJwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
    params = {jwtToken:response.data.body.token}
    const response1 = await axios.post(`${api.api_url}/purchaseManagement/purchaseOrder/getTypeBasedOnDesign`, params)
    if (response1 && response1.data && response1.data.body) {
     return {
      ListType:response1.data.body && response1.data.body.TypeList ? response1.data.body.TypeList  : []
     }
    } else {
     return {
      ListType:response1.data.body ? response1.data.body.TypeList : []
     }
    }
   } else {
     return {
      ListType:response1.data.body ? response1.data.body.TypeList : []
     }
    }
  } catch (error) {
    return {
      ListType:[]
    }    
  }  
})

//  GET Order LIST
export const PurchaseOrderListData = createAsyncThunk('PurchaseOrder/PurchaseOrderListData', async params => {
  try {
    const response = await axios.post(`${api.api_url}/purchaseManagement/purchaseOrder/purchaseOrderListJwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
    params['jwtToken'] = response.data.body.token
    const response1 = await axios.post(`${api.api_url}/purchaseManagement/purchaseOrder/purchaseOrderList`, params)
    if (response1 && response1.data && response1.data.body) {
     return {
      PurchaseOrderList:response1.data.body && response1.data.body.PurchaseOrderlist ? response1.data.body.PurchaseOrderlist  : [],
      TotalPurchaseOrder:response1.data.body && response1.data.body.TotalPurchaseOrder ? response1.data.body.TotalPurchaseOrder  : 0
     }
    } else {
     return {
      PurchaseOrderList: response1.data.body ? response1.data.body.PurchaseOrderlist : [],
      TotalPurchaseOrder: response1.data.body ? response1.data.body.TotalPurchaseOrder : 0
     }
    }
   } else {
     return {
      PurchaseOrderList:response1.data.body ? response1.data.body.PurchaseOrderlist : [],
      TotalPurchaseOrder: response1.data.body ? response1.data.body.TotalPurchaseOrder : 0
     }
    }
  } catch (error) {
    return {
      PurchaseOrderList:[],
      TotalPurchaseOrder: 0
    }    
  }  
})
//saveData Api
export const SaveData = createAsyncThunk('PurchaseOrder/SaveData', async params => {
  try {
    const response = await axios.post(`${api.api_url}/purchaseManagement/purchaseOrder/savePurchaseOrderJwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
    params = {jwtToken:response.data.body.token}
    const response1 = await axios.post(`${api.api_url}/purchaseManagement/purchaseOrder/savePurchaseOrder`, params)
    if (response1 && response1.data && response1.data.body) {
     return {
       statusFlag:response1.data.body && response1.data.body.statusFlag ? response1.data.body.statusFlag  : 2,
       message:response1.data.body.message
     }
    } else {
     return {
       statusFlag:response1.data.body ? response1.data.body.statusFlag : 2,
       message:response1.data.body ? response1.data.body.message : 'Server not reachable.Please try again later'
     }
    }
   } else {
     return {
       statusFlag:response1.data.body ? response1.data.body.statusFlag : 2,
       message:response1.data.body ? response1.data.body.message : 'Server not reachable.Please try again later'
     }
    }
  } catch (error) {
    return {
      statusFlag:2,
      message:'Server not reachable.Please try again later'
    }    
  }
  
})
//Edit Order List
export const EditData = createAsyncThunk('PurchaseOrder/EditData', async params => {
  try {
    const response = await axios.post(`${api.api_url}/purchaseManagement/purchaseOrder/editPurchaseOrderJwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
    params['jwtToken'] = response.data.body.token
    const response1 = await axios.post(`${api.api_url}/purchaseManagement/purchaseOrder/editPurchaseOrder`, params)
    if (response1 && response1.data && response1.data.body) {
     return {
      EditPurchaseOrderList:response1.data.body && response1.data.body.EditPurchaseOrderList ? response1.data.body.EditPurchaseOrderList  : []
     }
    } else {
     return {
      EditPurchaseOrderList: response1.data.body ? response1.data.body.EditPurchaseOrderList : []
     }
    }
   } else {
     return {
      EditPurchaseOrderList:response1.data.body ? response1.data.body.EditPurchaseOrderList : []
     }
    }
  } catch (error) {
    return {
      EditPurchaseOrderList:[]
    }    
  }  
})
//DeleteData Api
export const DeleteData = createAsyncThunk('PurchaseOrder/DeleteData', async params => {
  try {
    const response = await axios.post(`${api.api_url}/purchaseManagement/purchaseOrder/deletePurchaseOrderJwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
    params['jwtToken'] = response.data.body.token
    const response1 = await axios.post(`${api.api_url}/purchaseManagement/purchaseOrder/deletePurchaseOrder`, params)
    if (response1 && response1.data && response1.data.body) {
     return {
       statusFlag:response1.data.body && response1.data.body.statusFlag ? response1.data.body.statusFlag  : 2,
       message:response1.data.body.message
     }
    } else {
     return {
       statusFlag:response1.data.body ? response1.data.body.statusFlag : 2,
       message:response1.data.body ? response1.data.body.message : 'Server not reachable.Please try again later'
     }
    }
   } else {
     return {
       statusFlag:response1.data.body ? response1.data.body.statusFlag : 2,
       message:response1.data.body ? response1.data.body.message : 'Server not reachable.Please try again later'
     }
    }
  } catch (error) {
    return {
      statusFlag:2,
      message:'Server not reachable.Please try again later'
    }    
  }
  
})
//CancelData Api
export const CancelData = createAsyncThunk('PurchaseOrder/CancelData', async params => {
  try {
    const response = await axios.post(`${api.api_url}/purchaseManagement/purchaseOrder/cancelPurchaseOrderJwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
    params['jwtToken'] = response.data.body.token
    const response1 = await axios.post(`${api.api_url}/purchaseManagement/purchaseOrder/cancelPurchaseOrder`, params)
    if (response1 && response1.data && response1.data.body) {
     return {
       statusFlag:response1.data.body && response1.data.body.statusFlag ? response1.data.body.statusFlag  : 2,
       message:response1.data.body.message
     }
    } else {
     return {
       statusFlag:response1.data.body ? response1.data.body.statusFlag : 2,
       message:response1.data.body ? response1.data.body.message : 'Server not reachable.Please try again later'
     }
    }
   } else {
     return {
       statusFlag:response1.data.body ? response1.data.body.statusFlag : 2,
       message:response1.data.body ? response1.data.body.message : 'Server not reachable.Please try again later'
     }
    }
  } catch (error) {
    return {
      statusFlag:2,
      message:'Server not reachable.Please try again later'
    }    
  }
  
})

//saveData Api
export const saveMaterialData = createAsyncThunk('PurchaseOrder/saveMaterialData', async params => {
  try {
  const response = await axios.post(`${api.api_url}/master/material/saveMaterialJwt`, params)
   if (response && response.data && response.data.body && response.data.body.token) {
      params['jwtToken'] = response.data.body.token
   const response1 = await axios.post(`${api.api_url}/master/material/saveMaterial`, params)
   if (response1 && response1.data && response1.data.body) {
    return {
      Material_statusFlag:response1.data.body && response1.data.body.statusFlag ? response1.data.body.statusFlag : 2,
      Material_message:response1.data.body.message,
      MaterialId:response1.data.body.material_id,
      MaterialName:response1.data.body.material_name
    }
   } else {
    return {
      Material_statusFlag:response1.data.body ? response1.data.body.statusFlag : 2,
      Material_message:response1.data.body ? response1.data.body.message : 'Server not reachable.Please try again later',
      MaterialId:response1.data.body.material_id,
      MaterialName:response1.data.body.material_name
    }
   }
  } else {
    return {
      Material_statusFlag:response.data.body ? response.data.body.statusFlag : 2,
      Material_message:response.data.body ? response.data.body.message : 'Server not reachable.Please try again later',
      MaterialId:response1.data.body.material_id,
      MaterialName:response1.data.body.material_name
    }
   }
  } catch (error) {
    return {
      Material_statusFlag:2,
      Material_message:'Server not reachable.Please try again later',
      MaterialId:0,
      MaterialName:''
    }    
  }
})

//Print Order Slip
export const printOrderSlip = createAsyncThunk('PurchaseOrder/printOrderSlip', async params => {
  const response = await axios.post(`${api.api_url}/purchaseManagement/purchaseOrder/printOrderSlipjwt`, params)
  if (response && response.data && response.data.body && response.data.body.token) {
    params = {jwtToken:response.data.body.token}
    const response1 = await axios.post(`${api.api_url}/purchaseManagement/purchaseOrder/printOrderSlip`, params)
    if (response1 && response1.data && response1.data.body) {
      return {
        OrderSlipList: response1.data.body.OrderSlip,
        CompanyList: response1.data.body.CompanyArray,
        Order_CustomerList: response1.data.body.CustomerArray,
        ItemCount: response1.data.body.ItemCount,
        ItemLists : response1.data.body.ItemLists
      }
    } else {
    }
  }
}
)
export const printPurchaseOrderSlip = createAsyncThunk('PurchaseOrder/printPurchaseOrderSlip', async params => {
  try {
    const response = await axios.post(`${api.api_url}/purchaseManagement/purchaseOrder/printPurchaseOrderJwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
    params['jwtToken'] = response.data.body.token
    const response1 = await axios.post(`${api.api_url}/purchaseManagement/purchaseOrder/printPurchaseOrder`, params)
    if (response1 && response1.data && response1.data.body) {
     return {
      Print_PurchaseOrderList:response1.data.body && response1.data.body.PurchaseOrderList ? response1.data.body.PurchaseOrderList  : [],
      Print_CompanyList:response1.data.body && response1.data.body.CompanyArray ? response1.data.body.CompanyArray  : []
     }
    } else {
     return {
      Print_PurchaseOrderList: response1.data.body ? response1.data.body.PurchaseOrderList : [],
      Print_CompanyList: response1.data.body ? response1.data.body.CompanyArray : []
     }
    }
   } else {
     return {
      Print_PurchaseOrderList:response1.data.body ? response1.data.body.PurchaseOrderList : [],
      Print_CompanyList: response1.data.body ? response1.data.body.CompanyArray : []
     }
    }
  } catch (error) {
    return {
      Print_PurchaseOrderList:[],
      Print_CompanyList: []
    }    
  }  
})

export const getBrandList = createAsyncThunk('ItemgroupMaster/brandFilterListJwt', async params => {
  try {
  const response = await axios.post(`${api.api_url}/common/brandFilterListJwt`, params)
   if (response && response.data && response.data.body && response.data.body.token) {
   params['jwtToken'] = response.data.body.token
   const response1 = await axios.post(`${api.api_url}/common/brandFilterList`, params)
   if (response1 && response1.data && response1.data.body) {
    return {
      brand_List:response1.data.body && response1.data.body.BrandArray ? response1.data.body.BrandArray : [],
      brand_List_Message:response1.data.body.message
    }
   } else {
    return {
      brand_List:response1.data.body ? response1.data.body.BrandArray : [],
      brand_List_Message:response1.data.body ? response1.data.body.message : 'Server not reachable.Please try again later'
    }
   }  
  } else {
    return {
      brand_List:response.data.body ? response.data.body.BrandArray : [],
      brand_List_Message: response.data.body ? response.data.body.message : 'Server not reachable.Please try again later'
    }
   }
  } catch (error) {
    return {
      brand_List: [],
      brand_List_Message:'Server not reachable.Please try again later'
    }
  }
})


export const PurchaseOrderSlice = createSlice({
  name: 'PurchaseOrder',
  initialState: {
    ListUnit:[],  
    params: {},
    statusFlag:0,    
    message:'',    
    ListMaterial: [],
    ListType: [],
    ListDesign:[],
    Print_CompanyList:[],
    SupllierList:[],
    ListSupllier:[],
    Print_PurchaseOrderList:[],
    ItemCount:[],
    PurchaseOrderList:[],
    TotalPurchaseOrder:0,
    EditPurchaseOrderList:[],
    Order_CustomerList:[],
    ItemLists: [],
    Material_statusFlag:0,
    Material_message:'',
    MaterialId:0,
    MaterialName:'',
    brand_List: [],
    brand_List_Message:''
  },
  reducers: {
    handleStatusFlag: (state, action) => {
      state.statusFlag = action.payload.statusFlag
      state.message = action.payload.message
      state.Material_statusFlag = action.payload.Material_statusFlag
      state.Material_message = action.payload.Material_message 
    },
    handleedit: (state, action) => {
      state.editaction = action.payload
    },
    clearstoreData: (state, action) => {
      state.EditPurchaseOrderList = action.payload.EditPurchaseOrderList
      state.SupllierList = action.payload.SupllierList
      state.ListSupllier = action.payload.ListSupllier
      state.Print_PurchaseOrderList = action.payload.Print_PurchaseOrderList
      state.ListDesign = action.payload.ListDesign
      state.ListMaterial = action.payload.ListMaterial
      state.ListType = action.payload.ListType      
      state.ItemLists = action.payload.ItemLists    
      state.ListUnit = action.payload.ListUnit
      state.TotalPurchaseOrder = action.payload.TotalPurchaseOrder 
    }
  },
  extraReducers: builder => {
    builder.addCase(getPurchaseFilterData.fulfilled, (state, action) => {
      state.ListUnit = action.payload.ListUnit
    }).addCase(onchangeSupllierData.fulfilled, (state, action) => {
      state.ListSupllier = action.payload.ListSupllier
    }).addCase(onchangeSupplierDetails.fulfilled, (state, action) => {
      state.SupllierList = action.payload.SupllierList
    }).addCase(onchangeDesignData.fulfilled, (state, action) => {
      state.ListDesign = action.payload.ListDesign
    }).addCase(onchangeMaterialData.fulfilled, (state, action) => {
      state.ListMaterial = action.payload.ListMaterial
    }).addCase(getTypeBasedOnDesignData.fulfilled, (state, action) => {
      state.ListType = action.payload.ListType
    }).addCase(SaveData.fulfilled, (state, action) => {
      state.statusFlag = action.payload.statusFlag
      state.message = action.payload.message
    }).addCase(DeleteData.fulfilled, (state, action) => {
      state.statusFlag = action.payload.statusFlag
      state.message = action.payload.message
    }).addCase(CancelData.fulfilled, (state, action) => {
      state.statusFlag = action.payload.statusFlag
      state.message = action.payload.message
    }).addCase(EditData.fulfilled, (state, action) => {
      state.EditPurchaseOrderList = action.payload.EditPurchaseOrderList
    }).addCase(saveMaterialData.fulfilled, (state, action) => {
      state.Material_statusFlag = action.payload.Material_statusFlag
      state.Material_message = action.payload.Material_message
      state.MaterialId = action.payload.MaterialId
     state.MaterialName = action.payload.MaterialName
    }).addCase(printPurchaseOrderSlip.fulfilled, (state, action) => {
      state.Print_PurchaseOrderList = action.payload.Print_PurchaseOrderList
      state.Print_CompanyList = action.payload.Print_CompanyList
    })    
    .addCase(PurchaseOrderListData.fulfilled, (state, action) => {
      state.PurchaseOrderList = action.payload.PurchaseOrderList
      state.TotalPurchaseOrder = action.payload.TotalPurchaseOrder 
      state.params = action.payload.params
    }).addCase(getBrandList.fulfilled, (state, action) => {
      state.brand_List = action.payload.brand_List
      state.brand_List_Message = action.payload.brand_List_Message
    })
  }
})
export const {
  handleStatusFlag, handleedit, clearstoreData
} = PurchaseOrderSlice.actions
export default PurchaseOrderSlice.reducer
